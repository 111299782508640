import React from "react";

export function PrivacyPolicy() {
	return (
		<div>
			<header className="App-header">
				<h1>Privacy Policy</h1>
				<h2>DiffArte Mobile Application</h2>
				<p>last update: 20.08.2024</p>
			</header>
			<main
				style={{ padding: "2rem", display: "flex", flexDirection: "column" }}>
				<h3>
					The DiffArte app is committed to protecting its users' privacy by
					minimizing the processing of their personal data and using secure and
					trusted advertising partners (Google AdMob).
				</h3>
				<h3 style={{ paddingBottom: 50 }}>
					This Privacy Policy explains how the DiffArte app collects, processes,
					and protects its users' personal data. It has been prepared in
					accordance with the GDPR (General Data Protection Regulation) and the
					CCPA (California Consumer Privacy Act).
				</h3>
				<h2>1. Application Owner and Data Controller</h2>
				<p>
					The owner of the DiffArte app and the data controller of the users'
					personal data processed for the use of the DiffArte app is Aleksandra
					Przybyło, residing in Rakszawa 1514, 37-111 Rakszawa, Poland.
				</p>
				<p>
					You can contact the app owner regarding the processing of personal
					data at the following email address: diffarteapp@gmail.com.
				</p>
				<h2>2. Collection of Non-Personal Data</h2>
				<p>
					The DiffArte app may collect non-personal data to ensure the proper
					functioning of the app and to optimize the user experience. The
					DiffArte app may use the following for these purposes:
				</p>
				<ul>
					<li>
						The local storage of the user's device (to save game results,
						settings, etc.),
					</li>
					<li>The cache of the user's device (to buffer images).</li>
				</ul>
				<p>
					Non-personal data stored in the local and cache memory of the device
					can be manually deleted by the user at any time via the "Settings" and
					"Manage App Data"/"Storage" (or similar) options on their device.
				</p>
				<h2>3. Processing of Personal Data</h2>
				<p>
					The DiffArte app uses Google’s advertising services to display ads
					(AdMob) to maintain the free version of the app. Through the DiffArte
					app, Google (AdMob) may collect and process users' personal data, such
					as unique identifiers and cookies, for the following purposes:
				</p>
				<ul>
					<li>Personalizing ads and content,</li>
					<li>Measuring ads and content,</li>
					<li>Audience insights,</li>
					<li>Improving services.</li>
				</ul>
				<p>
					To display personalized ads, Google requires your consent. Until you
					provide this consent, Google will not begin collecting your personal
					data. You can change your consent at any time in the "ad privacy
					settings" within the DiffArte app ("Settings" → "Privacy Policy" → "Ad
					Privacy Settings"). There, you will also find detailed information
					about how Google may use your personal data and the privacy policies
					of external ad providers, which we encourage you to review carefully.
				</p>
				<p>
					To better understand Google's ad privacy policy, visit this page:{" "}
					<a href="https://policies.google.com/technologies/ads?hl=en-US">
						https://policies.google.com/technologies/ads?hl=en-US
					</a>
				</p>
				<p>
					To learn more about "How Google uses information from sites or apps
					that use our services," visit this page:{" "}
					<a href="https://policies.google.com/technologies/partner-sites?hl=en">
						https://policies.google.com/technologies/partner-sites?hl=en
					</a>
				</p>
				<p>
					To better understand Google's Privacy Policy, visit this page:{" "}
					<a href="https://policies.google.com/privacy?hl=en">
						https://policies.google.com/privacy?hl=en
					</a>
				</p>
				<h2>4. Rights of Users from the European Economic Area</h2>
				<ul>
					<li>
						<b>Right to be informed:</b> Individuals whose data is being
						processed must be informed about the processing of their data.
					</li>
					<li>
						<b>Right of access:</b> Individuals have the right to receive
						confirmation from the data controller as to whether their data is
						being processed, and if so, to access that data and receive a copy.
					</li>
					<li>
						<b>Right to rectification:</b> Individuals have the right to request
						and obtain the rectification of inaccurate data and the completion
						of incomplete data from the data controller.
					</li>
					<li>
						<b>Right to erasure / “right to be forgotten”:</b> Individuals may
						request the deletion of their personal data by an organization in
						specific situations.
					</li>
					<li>
						<b>Right to restriction of processing:</b> In certain circumstances,
						individuals may request the restriction of the processing of their
						data.
					</li>
					<li>
						<b>Right to data portability:</b> Individuals have the right to
						receive their data in a structured, commonly used, and
						machine-readable format.
					</li>
					<li>
						<b>Right to object:</b> Individuals may object to the processing of
						their personal data on grounds relating to their particular
						situation.
					</li>
					<li>
						<b>
							Right not to be subject to a decision based solely on automated
							processing:
						</b>
						Individuals have the right not to be subject to a fully automated
						decision (without any human intervention in the decision-making
						process) that has legal effects or significantly affects them.
					</li>
				</ul>
				<h2>5. Rights of California Users</h2>
				<ul>
					<li>
						<b>Right to Know:</b> Users have the right to know about the
						personal information a business collects about them and how it is
						used and shared.
					</li>
					<li>
						<b>Right to Delete:</b> Users have the right to request the deletion
						of personal information collected from them (with some exceptions).
					</li>
					<li>
						<b>Right to Opt-Out:</b> Users have the right to opt out of the sale
						or sharing of their personal information.
					</li>
					<li>
						<b>Right to Non-Discrimination:</b> Users have the right to not be
						discriminated against for exercising their CCPA rights.
					</li>
					<li>
						<b>Right to Correct:</b> Users have the right to correct inaccurate
						personal information that a business has about them.
					</li>
					<li>
						<b>Right to Limit:</b> Users have the right to limit the use and
						disclosure of sensitive personal information collected about them.
					</li>
				</ul>
				<h2>6. Children's Privacy</h2>
				<p>
					The DiffArte app does not knowingly collect or process personal data
					from underage users. The DiffArte app is intended for use only by
					adult users (18 years and older) and should not be used by underage
					users without the knowledge and consent of their parents or guardians.
				</p>
				<h2>7. Contact</h2>
				<p>
					For any questions regarding the processing of personal data, you can
					contact the owner of the DiffArte app via email at
					diffarteapp@gmail.com. If you contact us via email for support,
					assistance, or feedback, the emails will be stored for the purpose of
					ensuring the quality of the DiffArte app. Email addresses will only be
					used to respond to reported issues or suggestions and will never be
					used for marketing purposes.
				</p>
			</main>
		</div>
	);
}
