import React, { useEffect } from "react";

export const RedirectToStore = () => {
	useEffect(() => {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;

		if (/android/i.test(userAgent)) {
			// Przekierowanie do Google Play
			window.location.href =
				"https://play.google.com/store/apps/details?id=com.ale.przy.diffarteapp";
		} else if (/iPhone|iPad|iPod/i.test(userAgent)) {
			// Przekierowanie do App Store
			window.location.href =
				"https://apps.apple.com/us/app/diffarte/id6651855728";
		} else {
			// Przekierowanie do strony internetowej lub komunikat
			window.location.href = "https://diffarte.com.pl/appstores";
		}
	}, []);

	return (
		<div
			style={{
				paddingTop: 30,
				gap: 10,
				display: "flex",
				flexDirection: "column",
			}}>
			<img
				src="icon.png"
				style={{
					width: 140,
					borderRadius: 30,
					borderWidth: 3,
					borderStyle: "solid",
				}}
				alt=""
			/>
			<p style={{ fontSize: 25 }}>Redirecting...</p>
		</div>
	);
};
