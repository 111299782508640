import React from "react";
import styles from "./AppStores.module.css";
import { Link } from "react-router-dom";

export function AppStores() {
	return (
		<div className={styles.wrapper}>
			<div className={styles.contentWrapper}>
				<img src="icon.png" alt="" className={styles.icon} />
				<div className={styles.titlesWrapper}>
					<div className={styles.textWrapper}>
						<img src="logo.png" alt="" style={{ width: 150 }} />
						<p style={{ fontSize: 20, fontWeight: "700", margin: 0 }}>
							Find the differences in artworks!
						</p>
					</div>
					<div className={styles.buttonsWrapper}>
						<Link
							to="https://apps.apple.com/us/app/diffarte/id6651855728"
							target="blank"
							style={{ height: 36 }}>
							<img src="app-store.svg" style={{ height: "100%" }} />
						</Link>
						<Link
							to="https://play.google.com/store/apps/details?id=com.ale.przy.diffarteapp"
							target="blank"
							style={{ height: 36 }}>
							<img src="google-play.png" style={{ height: "100%" }} />
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
