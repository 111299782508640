import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { RedirectToStore } from "./RedirectToAppStore";
import { AppStores } from "./AppStores";

function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path="/" Component={PrivacyPolicy} />
					<Route path="/redirect" Component={RedirectToStore} />
					<Route path="/appstores" Component={AppStores} />
					<Route path="/privacypolicy" Component={PrivacyPolicy} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
